import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid, LinearProgress } from "@mui/material";

export default function SplashScreen() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid
        container
        className="fixed h-full w-full"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item>
          <img src="logo.png" alt="Logo" height={64} />
          <div style={{ margin: 3, marginTop: 10 }}>
            <LinearProgress />
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../redux/store";

const ProtectedRoute = ({ component }: { component: ReactNode }) => {
  const { auth } = useAppSelector((state) => state.user);
  let location = useLocation();

  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return <React.Fragment>{component}</React.Fragment>;
};

export default ProtectedRoute;

import {
  CssBaseline,
  Paper,
  List,
  ListSubheader,
  ListItemButton,
  Skeleton,
  ListItemAvatar,
  ListItemText,
  Grid,
  Box,
} from "@mui/material";
import React from "react";

export default function SplitListSkeleton() {
  const items = [1, 2, 3];

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper square elevation={3} sx={{ height: "100%" }}>
        <List disablePadding>
          <ListSubheader
            component={"div"}
            sx={{ bgcolor: "background.paper", py: 1 }}
          >
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <Skeleton height={28} width={156} />
              </Grid>
              <Grid item>
                <Skeleton height={32} width={84} />
              </Grid>
            </Grid>
          </ListSubheader>
          {items.map((subItem, subItemIndex) => {
            return (
              <ListItemButton
                key={`SplitListSkeleton-ListItemButton-${subItemIndex}`}
                divider
                disableRipple
                disableTouchRipple
              >
                <ListItemAvatar>
                  <Skeleton variant="circular" height={42} width={42} />
                </ListItemAvatar>
                <ListItemText
                  sx={{ justifyContent: "space-between" }}
                  secondaryTypographyProps={{ component: "div" }}
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Skeleton height={32} width={84} />
                      <Skeleton height={28} width={84} />
                    </Box>
                  }
                  secondary={<Skeleton height={20} width={126} />}
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <Skeleton variant="circular" height={24} width={24} />
                  <Skeleton variant="circular" height={24} width={24} />
                </Box>
              </ListItemButton>
            );
          })}
        </List>
      </Paper>
    </React.Fragment>
  );
}

import React from "react";
import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/login";
import Home from "./pages/home";
import Profile from "./pages/profile";
import MobileAppBar from "./components/MobileAppBar";
import Expenses from "./pages/expenses";
import LoadingBar from "./components/LoadingBar";
import { useAppSelector } from "./redux/store";
import Toast from "./components/Toast";
import useSystemTheme from "./hooks/useSystemTheme";
import Splits from "./pages/splits";
import useCommonData from "./hooks/useCommonData";

const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  useCommonData();
  const { isDarkMode } = useSystemTheme();
  const { loading, toast } = useAppSelector((state) => state.common);

  return (
    <React.Fragment>
      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <CssBaseline />
        <LoadingBar loading={loading} />
        <Toast {...toast} />
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route path="/" element={<ProtectedRoute component={<Home />} />} />
            <Route
              path="/expenses"
              element={<ProtectedRoute component={<Expenses />} />}
            />
            <Route
              path="/splits"
              element={<ProtectedRoute component={<Splits />} />}
            />
            <Route
              path="/profile"
              element={<ProtectedRoute component={<Profile />} />}
            />
          </Routes>
          <MobileAppBar />
        </BrowserRouter>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;

import React, { FormEvent, useState } from "react";
import {
  CircularProgress,
  Container,
  CssBaseline,
  Fade,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { login } from "../../redux/slices/userSlice";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../services/firebase";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import {
  showToast,
  startLoading,
  stopLoading,
} from "../../redux/slices/commonSlice";
import { LoadingButton } from "@mui/lab";

const errorMessages: { [key: string]: string } = {
  "auth/user-not-found": "Invalid Email Address",
  "auth/wrong-password": "Invalid Password",
  "auth/network-request-failed": "Network Issue",
};

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useAppSelector((state) => state.common);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e: FormEvent) => {
    e.preventDefault();
    dispatch(startLoading());

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const { user } = userCredential;
        dispatch(
          login({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            phoneNumber: user.phoneNumber,
            photoURL: user.photoURL,
            lastSignInTime: user.metadata.lastSignInTime,
            refreshToken: user.refreshToken,
          })
        );
        navigate("/");
      })
      .catch((error: any) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error({ errorCode, errorMessage });
        dispatch(
          showToast({ message: errorMessages[errorCode], severity: "error" })
        );
      })
      .finally(() => {
        dispatch(stopLoading());
      });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper square className="h-full">
        <Container maxWidth={"sm"} className="h-full">
          <Grid
            container
            className="h-full"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item>
              <form onSubmit={handleLogin} name="login-form">
                <Grid container spacing={3} justifyContent={"center"}>
                  <Grid item xs={12}>
                    <Fade in>
                      <Typography variant="h3" align="center" mb={6}>
                        Login
                      </Typography>
                    </Fade>
                  </Grid>

                  <Grid item xs={12}>
                    <Fade in>
                      <TextField
                        type="email"
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        fullWidth
                      />
                    </Fade>
                  </Grid>
                  <Grid item xs={12}>
                    <Fade in>
                      <TextField
                        type="password"
                        label="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        fullWidth
                      />
                    </Fade>
                  </Grid>
                  <Grid item xs={12}>
                    <Fade in>
                      <div style={{ marginTop: 10 }}>
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          size="large"
                          loading={loading}
                          loadingIndicator={
                            <CircularProgress color="inherit" size={26} />
                          }
                          fullWidth
                        >
                          Login
                        </LoadingButton>
                      </div>
                    </Fade>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </React.Fragment>
  );
}

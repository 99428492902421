import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Fade } from "@mui/material";

type LoadingBarPropsType = {
  loading: boolean;
};

export default function LoadingBar(props: LoadingBarPropsType) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 98) {
          return 98;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 100);

    return () => {
      setProgress(100);
      clearInterval(timer);
    };
  }, []);

  return (
    <Fade in={props.loading}>
      <Box sx={{ position: "fixed", top: 0, width: "100%" }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
    </Fade>
  );
}

import {
  CssBaseline,
  Paper,
  List,
  ListSubheader,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import React from "react";
import { UserStateExpenseType } from "../../types";
import CustomAvatar from "../../components/CustomAvatar";
import moment from "moment";
import ViewExpense from "./ViewExpense";
import { filter } from "lodash";
import { currencyFormatter } from "../../helpers";
import NoDataPlaceholder from "../../components/NoDataPlaceholder";

type ChoresListPropsType = {
  items: UserStateExpenseType[];
  handleDeleteExpense?: (arg: string) => void;
};

export default function ExpenseList(props: ChoresListPropsType) {
  const [viewExpenseOpen, setViewExpenseOpen] = React.useState(false);
  const [viewExpenseData, setViewExpenseData] =
    React.useState<UserStateExpenseType>({});

  const handleItemClick = (item: UserStateExpenseType) => {
    setViewExpenseData(item);
    setViewExpenseOpen(true);
  };

  const handleItemClose = () => {
    setViewExpenseOpen(false);
    setViewExpenseData({});
  };

  const logDates: string[] = [];

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper square elevation={3} sx={{ height: "100%" }}>
        {props.items.length === 0 && <NoDataPlaceholder />}
        {props.items.length > 0 && (
          <List disablePadding>
            {props.items.map((item) => {
              const { id, user, createdAt, date, details, amount } = item;
              const expenseDate = moment(date).format("LL");
              const logDate = moment(createdAt).format("LL");
              const logTime = moment(createdAt).format("LT");
              logDates.push(logDate);

              return (
                <React.Fragment key={`list-${id}`}>
                  {filter(logDates, (i) => i === logDate).length >= 2 ? null : (
                    <ListSubheader>{logDate}</ListSubheader>
                  )}

                  <ListItemButton divider onClick={() => handleItemClick(item)}>
                    <ListItemAvatar>
                      {user?.displayName && (
                        <CustomAvatar name={user?.displayName} />
                      )}
                      {!user?.displayName && <Avatar></Avatar>}
                    </ListItemAvatar>
                    <ListItemText
                      primaryTypographyProps={{ component: "div" }}
                      secondaryTypographyProps={{
                        component: "div",
                      }}
                      primary={
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Grid item xs>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Typography
                                fontWeight={600}
                                variant="subtitle1"
                                color={"limegreen"}
                              >
                                {currencyFormatter.format(Number(amount))}
                              </Typography>
                              <Typography variant="subtitle2" fontWeight={100}>
                                {expenseDate}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2" textAlign={"right"}>
                              {user?.displayName}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                      secondary={
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Grid item>
                            <Typography
                              variant="body2"
                              maxWidth={"60vw"}
                              noWrap
                            >
                              {details}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="caption" textAlign={"right"}>
                              {logTime}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItemButton>
                </React.Fragment>
              );
            })}
          </List>
        )}
      </Paper>
      <ViewExpense
        open={viewExpenseOpen}
        setOpen={handleItemClose}
        viewExpenseData={viewExpenseData}
        handleDeleteExpense={props.handleDeleteExpense}
      />
    </React.Fragment>
  );
}

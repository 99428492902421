import { collection, query, orderBy, limit, getDocs, doc, updateDoc, addDoc, writeBatch, getDoc } from "firebase/firestore";
import { firestore } from ".";
import { SplitsType, SplitsUserType } from "../../types";
import { uniqBy } from "lodash";

export const getSplits = async () => {
  const splitRef = collection(firestore, "splits")
  const q = query(splitRef, orderBy("createdAt", "asc"), limit(100));
  const querySnapshot = await getDocs(q);
  const splits: SplitsType[] = []
  querySnapshot.forEach((doc) => {
    splits.push({
      ...doc.data(),
      id: doc.id,
    });
  });

  return splits;
}

export const generateSplitExpenses = async (expenseIds: string[], splitUsers: SplitsUserType[]) => {
  const splitRef = collection(firestore, "splits")
  const expenseRefs = expenseIds.map(expenseId => doc(firestore, "expenses", expenseId))
  const newSplitRef = await addDoc(splitRef, {
    expenseRefs,
    users: splitUsers.map(splitUser => ({
      amount: splitUser.amount,
      paidByRefs: [],
      userRef: splitUser.userRef
    })),
    createdAt: new Date().toISOString()
  })
  const batch = writeBatch(firestore);
  expenseRefs.forEach(expenseRef => {
    batch.update(expenseRef, { status: "split" })
  })
  await batch.commit();

  console.log(newSplitRef);
  const result = await getDoc(newSplitRef)
  const newSplit = {
    ...result.data(),
    id: result.id
  }
  return newSplit;
}

export const markPaidBy = async (split: SplitsType, userId: string, paidById: string) => {
  if (split.id) {
    const paidByDocRef = doc(firestore, "users", paidById)
    const splitDocRef = doc(firestore, "splits", split.id)

    if (splitDocRef.id && paidByDocRef.id) {
      const splitUsers = split.users?.map(sUser => {
        const paidByRefs = []
        if (sUser.user?.id === userId) {
          paidByRefs.push(paidByDocRef)
        }
        return {
          amount: sUser.amount,
          userRef: sUser.userRef,
          paidByRefs: uniqBy([...sUser.paidByRefs as [], ...paidByRefs], "id"),
        }
      })

      const updatedSplit = {
        createdAt: split.createdAt,
        expenseRefs: split.expenseRefs,
        users: splitUsers
      }

      await updateDoc(splitDocRef, updatedSplit)
      return {
        ...split,
        ...updatedSplit
      }
    } else {
      throw new Error("Missing Split or Paid by information");
    }
  } else {
    throw new Error("Missing Split information");
  }
}

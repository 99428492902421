import {
  CssBaseline,
  Paper,
  List,
  ListSubheader,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Typography,
  Skeleton,
  Grid,
} from "@mui/material";
import React from "react";

export default function ChoreListSkeleton() {
  const items = [1, 2, 3];

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper square elevation={3} sx={{ height: "100%" }}>
        <List disablePadding>
          {items.map((item, itemIndex) => {
            return (
              <React.Fragment key={`ChoresListSkeleton-${itemIndex}`}>
                {item === 1 && (
                  <ListSubheader sx={{ bgcolor: "background.paper", py: 1 }}>
                    <Skeleton height={32} width={84} />
                  </ListSubheader>
                )}
                {item === 3 && (
                  <ListSubheader sx={{ bgcolor: "background.paper", py: 1 }}>
                    <Skeleton height={32} width={84} />
                  </ListSubheader>
                )}
                <ListItemButton divider disableRipple disableTouchRipple>
                  <ListItemAvatar>
                    <Skeleton variant="circular" height={42} width={42} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <Grid item>
                          <Skeleton height={28} width={124} sx={{ mb: 0.2 }} />
                        </Grid>
                        <Grid item>
                          <Typography variant="caption">
                            <Skeleton width={64} />
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                    secondary={<Skeleton width={"45vw"} />}
                  />
                </ListItemButton>
              </React.Fragment>
            );
          })}
        </List>
      </Paper>
    </React.Fragment>
  );
}

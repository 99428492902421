import { ButtonProps, IconButton } from "@mui/material";
import { ReactNode } from "react";

export default function IconButtonWithLabel({
  children,
  ...buttonProps
}: {
  children: ReactNode;
} & ButtonProps) {
  return (
    <IconButton
      className="h-full"
      color="inherit"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: "0px",
      }}
      {...buttonProps}
    >
      {children}
    </IconButton>
  );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  UpdateActionPayloadType,
  UserObjectType,
  UserStateDailyChoreType,
  UserStateExpenseType,
  UserStateType
} from "../../types";
import { filter, orderBy } from "lodash";

const initialState: UserStateType = {
  initialize: ["authUser", "dailyChores", "expenses"],
  auth: false,
}

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserObjectType>) => {
      state.authUser = action.payload
      state.auth = true
      state.initialize = filter(state.initialize, i => i !== "authUser")
    },
    update: (state, action: PayloadAction<UpdateActionPayloadType>) => {
      state.authUser = {
        ...state.authUser,
        displayName: action.payload.displayName
      }
      state.auth = true
    },
    loadDailyChores: (state, action: PayloadAction<UserStateDailyChoreType[]>) => {
      state.dailyChores = action.payload
      state.initialize = filter(state.initialize, i => i !== "dailyChores")
    },
    loadExpenses: (state, action: PayloadAction<UserStateExpenseType[]>) => {
      state.expenses = orderBy(action.payload, "createdAt", "desc")
      state.initialize = filter(state.initialize, i => i !== "expenses")
    },
    injectExpenses: (state, action: PayloadAction<UserStateExpenseType>) => {
      state.expenses = orderBy([...state.expenses || [], action.payload], "createdAt", "desc")
    },
    takeOutExpenses: (state, action: PayloadAction<string>) => {
      state.expenses = orderBy(filter(state.expenses, e => e.id !== action.payload), "createdAt", "desc")
    },
    logout: () => initialState
  }
})

export const { login, update, loadDailyChores, loadExpenses, injectExpenses, takeOutExpenses, logout } = userSlice.actions
export default userSlice.reducer

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";

type SplitConfirmationPropsType = {
  open: boolean;
  handleClose: () => void;
  handleApprove: () => void;
};

export default function SplitConfirmation(props: SplitConfirmationPropsType) {
  const { open, handleClose, handleApprove } = props;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="SplitConfirmation-dialog-title"
        aria-describedby="SplitConfirmation-dialog-description"
        fullWidth
      >
        <DialogTitle id="SplitConfirmation-dialog-title">
          {"Are you sure you want to split?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="SplitConfirmation-dialog-description">
            Are you sure you want to perform split operation for current
            expenses? Once done this action can not be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs>
              <Button
                variant="contained"
                color="error"
                onClick={handleClose}
                fullWidth
              >
                No
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                variant="contained"
                color="success"
                onClick={handleApprove}
                fullWidth
                autoFocus
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import { useState, useEffect } from "react";

interface Theme {
  isDarkMode?: boolean; // Optional property for light/dark theme detection
  [key: string]: any; // Allow for additional theme properties
}

export default function useSystemTheme(): Theme {
  const [theme, setTheme] = useState<Theme>({ isDarkMode: undefined });

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)"); // Detect dark mode preference

    const updateTheme = () => {
      setTheme({ isDarkMode: mediaQuery.matches }); // Update theme based on preference
    };

    mediaQuery.addEventListener("change", updateTheme); // Listen for preference changes

    updateTheme(); // Initial check

    return () => mediaQuery.removeEventListener("change", updateTheme); // Cleanup
  }, []);

  return theme;
}

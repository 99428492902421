import { Avatar, AvatarProps, SxProps, Theme } from "@mui/material";

type CustomAvatarPropsType = AvatarProps & {
  photo?: string | null;
  name?: string | null;
};

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string, propsSX?: SxProps<Theme>) {
  const nameSplit = name.split(" ");
  return {
    sx: {
      bgcolor: stringToColor(name),
      fontWeight: "bold",
      ...propsSX,
    },
    children: `${nameSplit?.[0][0]}${nameSplit[1] ? nameSplit[1][0] : ""}`,
  };
}

export default function CustomAvatar(props: CustomAvatarPropsType) {
  const { photo, name } = props;
  if (photo) {
    <Avatar src={photo} alt="Avatar" {...props} />;
  } else if (name) {
    return <Avatar {...props} {...stringAvatar(name, props.sx)} />;
  }
  return <Avatar {...props} />;
}

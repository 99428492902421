import React, { FormEvent, useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Fade,
  Paper,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { logout, update } from "../../redux/slices/userSlice";
import { useAppSelector } from "../../redux/store";
import { updateAuthProfile } from "../../services/firebase/user.service";
import {
  showToast,
  startLoading,
  stopLoading,
} from "../../redux/slices/commonSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import CustomAvatar from "../../components/CustomAvatar";

export default function Profile() {
  const dispatch = useDispatch();
  const { authUser } = useAppSelector((state) => state.user);
  const { loading } = useAppSelector((state) => state.common);

  const [displayName, setDisplayName] = useState(authUser?.displayName || "");

  const handleUpdate = async (e: FormEvent) => {
    e.preventDefault();
    dispatch(startLoading());
    await updateAuthProfile({ displayName });
    dispatch(update({ displayName }));
    dispatch(stopLoading());
    dispatch(showToast({ message: "Profile Update", severity: "success" }));
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container sx={{ p: 0 }} className="mobile-safe-area" maxWidth={"sm"}>
        <Paper square elevation={0} sx={{ p: 3, height: "100%" }}>
          <Grid container alignItems={"center"} spacing={3}>
            <Grid item xs={12}>
              <form onSubmit={handleUpdate} name="login-form">
                <Grid container spacing={3} justifyContent={"center"}>
                  <Grid item xs={12}>
                    <Fade in>
                      <Grid container justifyContent={"center"}>
                        <CustomAvatar
                          name={authUser?.displayName}
                          sx={{
                            height: 92,
                            width: 92,
                            fontSize: 56,
                            borderRadius: 2,
                          }}
                        />
                      </Grid>
                    </Fade>
                  </Grid>

                  <Grid item xs={12}>
                    <Fade in>
                      <TextField
                        type="text"
                        label="Name"
                        value={displayName}
                        onChange={(e) => setDisplayName(e.target.value)}
                        required
                        fullWidth
                      />
                    </Fade>
                  </Grid>

                  <Grid item xs={12}>
                    <Fade in>
                      <TextField
                        type="email"
                        label="Email"
                        value={authUser?.email}
                        fullWidth
                        disabled
                      />
                    </Fade>
                  </Grid>
                  <Grid item xs={12}>
                    <Fade in>
                      <div style={{ marginTop: 10 }}>
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          size="large"
                          loading={loading}
                          loadingIndicator={
                            <CircularProgress color="inherit" size={26} />
                          }
                          fullWidth
                        >
                          Update
                        </LoadingButton>
                      </div>
                    </Fade>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12}>
              <Fade in>
                <Button
                  color="error"
                  variant="contained"
                  size="large"
                  onClick={handleLogout}
                  fullWidth
                >
                  Logout
                </Button>
              </Fade>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  );
}

import { collection, getDocs, limit, orderBy, query } from "firebase/firestore"
import { firestore } from "."
import { CommonStateChoreType, UserStateDailyChoreType } from "../../types";

export const getAllChores = async () => {
  const choreRef = collection(firestore, "chores")
  const q = query(choreRef, limit(10));
  const querySnapshot = await getDocs(q);
  const chores: CommonStateChoreType[] = []
  querySnapshot.forEach((doc) => {
    chores.push({
      id: doc.id,
      ...doc.data()
    });
  });
  return chores;
}

export const getDailyChores = async () => {
  const dailyChoreRef = collection(firestore, "daily-chores")
  const q = query(dailyChoreRef, orderBy("order", "asc"), limit(10));
  const querySnapshot = await getDocs(q);
  const dailyChores: UserStateDailyChoreType[] = []
  querySnapshot.forEach((doc) => {
    dailyChores.push({
      ...doc.data(),
      id: doc.id,
    });
  });

  return dailyChores;
}

import { Alert, AlertColor, Slide, SlideProps, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hideToast } from "../redux/slices/commonSlice";

type ToastPropsType = {
  message?: string;
  severity?: AlertColor;
};

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

export default function Toast(props: ToastPropsType) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleExited = () => {
    dispatch(hideToast());
  };

  useEffect(() => {
    if (props.message) {
      setOpen(true);
    }
  }, [props.message]);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      TransitionProps={{ onExited: handleExited }}
      autoHideDuration={3000}
    >
      <Alert
        onClose={handleClose}
        severity={props.severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
}

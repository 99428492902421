import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Container, Fade, Grid, Slide, TextField } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CalendarMonth, Close, CurrencyRupee, Done } from "@mui/icons-material";

type AddExpensePropsType = {
  open: boolean;
  setOpen: (arg: boolean) => void;
  handleAddExpense: (arg1: number, arg2: string, arg3: string) => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddExpense(props: AddExpensePropsType) {
  const { open, setOpen } = props;
  const [amount, setAmount] = React.useState("");
  const [date, setDate] = React.useState("");
  const [details, setDetails] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    if (amount && date && details) {
      props.handleAddExpense(
        Number(amount),
        new Date(date).toISOString(),
        details
      );
      setOpen(false);
    }
  };

  React.useEffect(() => {
    if (!open) {
      setAmount("");
      setDate("");
      setDetails("");
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      maxWidth={"sm"}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullScreen
    >
      <DialogTitle variant="h4" fontWeight={600}>
        Add Expense
      </DialogTitle>
      <DialogContent dividers sx={{ py: 4 }}>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Fade in>
                <TextField
                  id="expense-amount"
                  type="number"
                  placeholder="Enter Amount"
                  label="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  InputProps={{
                    inputProps: {
                      style: {
                        textAlign: "center",
                      },
                    },
                    startAdornment: <CurrencyRupee />,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  fullWidth
                />
              </Fade>
            </Grid>
            <Grid item xs={12}>
              <Fade in>
                <TextField
                  id="expense-date"
                  type="date"
                  label="Date"
                  placeholder="Select Date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  InputProps={{
                    inputProps: {
                      style: {
                        textAlign: "center",
                      },
                    },
                    startAdornment: <CalendarMonth />,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  fullWidth
                />
              </Fade>
            </Grid>
            <Grid item xs={12}>
              <Fade in>
                <TextField
                  id="expense-details"
                  type="details"
                  placeholder="Enter Details"
                  label="Details"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  rows={8}
                  multiline
                  required
                  fullWidth
                />
              </Fade>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Container sx={{ p: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs>
              <Button
                size="large"
                color="error"
                variant="contained"
                onClick={handleClose}
                startIcon={<Close />}
                fullWidth
              >
                Close
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                size="large"
                color="success"
                variant="contained"
                onClick={handleSubmit}
                startIcon={<Done />}
                fullWidth
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Container>
      </DialogActions>
    </Dialog>
  );
}

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Zoom } from "@mui/material";
import { UserStateChoreUsernameType } from "../../types";
import { TransitionProps } from "@mui/material/transitions";

type MarkPaidConfirmationPropsType = {
  open: boolean;
  user: UserStateChoreUsernameType;
  handleClose: () => void;
  handleApprove: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

export default function MarkPaidConfirmation(
  props: MarkPaidConfirmationPropsType
) {
  const { open, user, handleClose, handleApprove } = props;

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="MarkPaidConfirmation-dialog-title"
        aria-describedby="MarkPaidConfirmation-dialog-description"
        TransitionComponent={Transition}
        sx={{ p: 4 }}
      >
        <DialogTitle id="MarkPaidConfirmation-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="MarkPaidConfirmation-dialog-description">
            Confirming you received the split payment from {user.displayName}.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs>
              <Button
                variant="contained"
                color="error"
                onClick={handleClose}
                fullWidth
              >
                No
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                variant="contained"
                color="success"
                onClick={handleApprove}
                fullWidth
                autoFocus
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

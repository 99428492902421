import { useEffect, useState } from "react";
import { useAppSelector } from "../redux/store";
import { useDispatch } from "react-redux";
import { showToast } from "../redux/slices/commonSlice";
import useCommonData from "./useCommonData";
import {
  injectExpenses,
  loadExpenses,
  takeOutExpenses,
} from "../redux/slices/userSlice";
import { AddExpensePayloadType, UserStateExpenseType } from "../types";
import {
  addExpense,
  deleteExpense,
  getExpenses,
} from "../services/firebase/expense.service";
import { find, sumBy } from "lodash";

export default function useExpenses() {
  const dispatch = useDispatch();
  const { fetchAllUsers } = useCommonData();
  const { initialize, authUser, expenses } = useAppSelector(
    (state) => state.user
  );
  const { commonInitialize, users } = useAppSelector((state) => state.common);

  const [expenseTotal, setExpenseTotal] = useState(0);
  const [initExpenses, setInitExpenses] = useState(true);

  const getUser = (path: string) => {
    return find(users, (u) => `users/${u.id}` === path);
  };

  const prepareExpense = (expense: UserStateExpenseType) => {
    const user = getUser(expense.userRef?.path || "");
    return {
      ...expense,
      user,
    };
  };

  const prepareExpenses = (data: UserStateExpenseType[]) => {
    const expenses = data.map((expense) => {
      return prepareExpense(expense);
    });
    dispatch(loadExpenses(expenses));
  };

  const fetchExpenses = () => {
    if (commonInitialize.includes("users")) {
      return;
    }
    // Get Daily Chores
    getExpenses()
      .then((result) => {
        prepareExpenses(result);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error({ errorCode, errorMessage });
        dispatch(showToast({ message: errorCode, severity: "error" }));
      });
  };

  const pushNewExpense = (expense: AddExpensePayloadType) => {
    // Add Expense
    addExpense(expense)
      .then((result) => {
        const expense = prepareExpense(result);
        dispatch(injectExpenses(expense));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error({ errorCode, errorMessage });
        dispatch(showToast({ message: errorCode, severity: "error" }));
      });
  };

  const dropExpense = (id: string) => {
    // Add Expense
    deleteExpense(id)
      .then(() => {
        dispatch(takeOutExpenses(id));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error({ errorCode, errorMessage });
        dispatch(showToast({ message: errorCode, severity: "error" }));
      });
  };

  useEffect(() => {
    if (!initialize.includes("expenses")) {
      setInitExpenses(false);
    }
  }, [initialize]);

  useEffect(() => {
    setExpenseTotal(sumBy(expenses, "amount"));
  }, [expenses]);

  useEffect(() => {
    fetchAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    fetchExpenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    const timer = setInterval(() => {
      fetchExpenses();
    }, 30000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    initExpenses,
    initialize,
    authUser,
    expenses,
    expenseTotal,
    pushNewExpense,
    dropExpense,
  };
}

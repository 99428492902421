import React, { useEffect, useState } from "react";
import {
  Container,
  CssBaseline,
  Fade,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import SplitListSkeleton from "./SplitListSkeleton";
import useSplits from "../../hooks/useSplits";
import SplitList from "./SplitList";
import ViewSplit from "./ViewSplit";
import { ViewSplitType } from "../../types";
import { find } from "lodash";

export default function Splits() {
  const { initSplits, userSplits } = useSplits();
  const [viewSplit, setViewSplit] = useState<ViewSplitType>({});

  useEffect(() => {
    if (viewSplit.id) {
      const updatedSplit = find(
        userSplits,
        (uSplit) => uSplit.id === viewSplit.split?.id
      );
      if (updatedSplit) {
        const updatedViewSplit = find(
          updatedSplit.users,
          (uSplitUser) => uSplitUser.user?.id === viewSplit.user?.id
        );
        if (updatedViewSplit) {
          setViewSplit({
            ...viewSplit,
            ...updatedViewSplit,
            split: {
              ...viewSplit.split,
              ...updatedSplit,
            },
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSplits]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Container sx={{ p: 0 }} className="mobile-safe-area">
        <Paper square>
          <Fade in>
            <Grid container alignItems={"center"} px={2} py={3} spacing={1}>
              <Grid item>
                <Typography variant="h5" fontWeight={500}>
                  Splits
                </Typography>
              </Grid>
            </Grid>
          </Fade>
          {initSplits && <SplitListSkeleton />}
          {!initSplits && (
            <SplitList
              items={userSplits}
              setViewSplit={(arg) => setViewSplit(arg)}
            />
          )}
        </Paper>
        <ViewSplit
          viewSplit={viewSplit}
          open={Object.keys(viewSplit).length !== 0}
          closeViewSplit={() => setViewSplit({})}
        />
      </Container>
    </React.Fragment>
  );
}

import { useEffect } from "react";
import { useAppSelector } from "../redux/store";
import { useDispatch } from "react-redux";
import { loadUsers, showToast, loadChores } from "../redux/slices/commonSlice";
import { getAllChores } from "../services/firebase/chore.service";
import { getAllUsers } from "../services/firebase/user.service";

export default function useCommonData() {
  const dispatch = useDispatch();
  const { auth } = useAppSelector((state) => state.user);

  const fetchAllUsers = () => {
    // Get All User details
    getAllUsers()
      .then((data) => {
        dispatch(loadUsers(data));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error({ errorCode, errorMessage });
        dispatch(showToast({ message: errorCode, severity: "error" }));
      });
  };

  const fetchAllChores = () => {
    // Get Chore list
    getAllChores()
      .then((data) => {
        dispatch(loadChores(data));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error({ errorCode, errorMessage });
        dispatch(showToast({ message: errorCode, severity: "error" }));
      });
  };

  useEffect(() => {
    if (auth) {
      fetchAllUsers();
      fetchAllChores();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return { fetchAllUsers, fetchAllChores };
}

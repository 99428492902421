import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CommonStateChoreType,
  CommonStateToastType,
  CommonStateType,
  CommonStateUserType,
  SplitsType
} from "../../types";
import { filter, orderBy } from "lodash";

const initialState: CommonStateType = {
  commonInitialize: ["users", "chores", "userSplits"],
  loading: false,
  toast: {},
  users: [],
  chores: [],
  userSplits: []
}

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true
    },
    stopLoading: (state) => {
      state.loading = false
    },
    showToast: (state, action: PayloadAction<CommonStateToastType>) => {
      state.toast = action.payload
    },
    hideToast: (state) => {
      state.toast = {}
    },
    loadUsers: (state, action: PayloadAction<CommonStateUserType[]>) => {
      state.users = action.payload
      state.commonInitialize = filter(state.commonInitialize, i => i !== "users")
    },
    loadChores: (state, action: PayloadAction<CommonStateChoreType[]>) => {
      state.chores = action.payload
      state.commonInitialize = filter(state.commonInitialize, i => i !== "chores")
    },
    loadUserSplits: (state, action: PayloadAction<SplitsType[]>) => {
      state.userSplits = orderBy(action.payload, "createdAt", "desc")
      state.commonInitialize = filter(state.commonInitialize, i => i !== "userSplits")
    },
    injectUserSplit: (state, action: PayloadAction<SplitsType>) => {
      state.userSplits = orderBy([...state.userSplits || [], action.payload], "createdAt", "desc")
    },
  }
})

export const { startLoading, stopLoading, showToast, hideToast, loadUsers, loadUserSplits, loadChores, injectUserSplit } = commonSlice.actions
export default commonSlice.reducer

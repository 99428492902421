import {
  CssBaseline,
  Paper,
  List,
  ListSubheader,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Typography,
  Skeleton,
  Box,
  Grid,
} from "@mui/material";
import React from "react";

export default function ExpenseListSkeleton() {
  const items = [1, 2, 3];

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper square elevation={3} sx={{ height: "100%" }}>
        <List disablePadding>
          {items.map((item, itemIndex) => {
            return (
              <React.Fragment key={`ChoresListSkeleton-${itemIndex}`}>
                {item === 1 && (
                  <ListSubheader sx={{ bgcolor: "background.paper", py: 1 }}>
                    <Skeleton height={32} width={124} />
                  </ListSubheader>
                )}
                {item === 3 && (
                  <ListSubheader sx={{ bgcolor: "background.paper", py: 1 }}>
                    <Skeleton height={32} width={124} />
                  </ListSubheader>
                )}
                <ListItemButton divider disableRipple disableTouchRipple>
                  <ListItemAvatar>
                    <Skeleton variant="circular" height={42} width={42} />
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{ component: "div" }}
                    secondaryTypographyProps={{
                      component: "div",
                    }}
                    primary={
                      <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Grid item xs>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Skeleton height={32} width={64} />
                            <Skeleton height={24} width={84} />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle2" textAlign={"right"}>
                            <Skeleton width={84} />
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                    secondary={
                      <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <Skeleton width={126} />
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" textAlign={"right"}>
                            <Skeleton width={64} />
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItemButton>
              </React.Fragment>
            );
          })}
        </List>
      </Paper>
    </React.Fragment>
  );
}

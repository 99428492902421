import { CurrencyRupee, Person, ListAlt, MenuBook } from "@mui/icons-material";
import { AppBar, Toolbar, Grid, Typography, Badge, Slide } from "@mui/material";
import IconButtonWithLabel from "./IconButtonWithLabel";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/store";
import { useEffect, useState } from "react";

type PathEnumType = "/" | "/expenses" | "/splits" | "/profile";

export default function MobileAppBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useAppSelector((state) => state.user);
  const [page, setPage] = useState("");

  const handlePageChange = (path: PathEnumType) => {
    navigate(path);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setPage("home");
    } else {
      setPage(location.pathname.replace("/", ""));
    }
  }, [location]);

  return (
    <Slide direction="up" in={auth}>
      <AppBar
        position="fixed"
        color="default"
        sx={{ top: "auto", bottom: 0, height: 92 }}
        className="add-safe-area-bottom"
      >
        <Toolbar className="h-full" sx={{ p: 0 }}>
          <Grid
            container
            className="h-full"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item xs={3} className="h-full">
              <IconButtonWithLabel
                onClick={() => handlePageChange("/")}
                color={page === "home" ? "primary" : "inherit"}
              >
                <ListAlt sx={{ height: 32, width: 32 }} />
                <Typography variant="caption">Chores</Typography>
              </IconButtonWithLabel>
            </Grid>
            <Grid item xs={3} className="h-full">
              <IconButtonWithLabel
                onClick={() => handlePageChange("/expenses")}
                color={page === "expenses" ? "primary" : "inherit"}
              >
                <MenuBook sx={{ height: 32, width: 32 }} />
                <Typography variant="caption">Expenses</Typography>
              </IconButtonWithLabel>
            </Grid>
            <Grid item xs={3} className="h-full">
              <IconButtonWithLabel
                onClick={() => handlePageChange("/splits")}
                color={page === "splits" ? "primary" : "inherit"}
              >
                <Badge color="error" badgeContent={0}>
                  <CurrencyRupee sx={{ height: 32, width: 32 }} />
                </Badge>
                <Typography variant="caption">Splits</Typography>
              </IconButtonWithLabel>
            </Grid>
            <Grid item xs={3} className="h-full">
              <IconButtonWithLabel
                onClick={() => handlePageChange("/profile")}
                color={page === "profile" ? "primary" : "inherit"}
              >
                <Person sx={{ height: 32, width: 32 }} />
                <Typography variant="caption">Profile</Typography>
              </IconButtonWithLabel>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Slide>
  );
}

import {
  CssBaseline,
  Paper,
  List,
  ListSubheader,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Grid,
} from "@mui/material";
import React from "react";
import { UserStateDailyChoreType } from "../../types";
import {
  AutoAwesome,
  Checkroom,
  CleaningServices,
  LocalLaundryService,
  Restaurant,
} from "@mui/icons-material";

type ChoresListPropsType = {
  items: UserStateDailyChoreType[];
};

const choreIcons: { [key: string]: JSX.Element } = {
  laundry: <LocalLaundryService color="action" />,
  utensils: <Restaurant color="action" />,
  cleaning: <CleaningServices color="action" />,
  "laundry-fold": <Checkroom color="action" />,
};

export default function ChoreList(props: ChoresListPropsType) {
  const dayOfTheWeek = new Date().getDay();
  const orderByWeekday = (
    weekdays: UserStateDailyChoreType[]
  ): UserStateDailyChoreType[] => {
    const today = new Date().getDay();

    const sortedWeekdays = weekdays
      .slice(today)
      .concat(weekdays.slice(0, today));
    return sortedWeekdays;
  };

  const orderedDailyChores = orderByWeekday(props.items);

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper square elevation={3} sx={{ height: "100%" }}>
        <List disablePadding>
          {orderedDailyChores.map(({ id, chores, order }) => {
            const todayChore = dayOfTheWeek === order;
            return (
              <React.Fragment key={id}>
                <ListSubheader
                  sx={{
                    textTransform: "capitalize",
                    opacity: todayChore ? 1 : 0.5,
                  }}
                  disableSticky={!todayChore}
                  onClick={() => {
                    window?.scrollTo(0, 0);
                  }}
                >
                  {id}
                </ListSubheader>
                {chores?.map(({ items, username }, choreIndex) => {
                  return (
                    <React.Fragment
                      key={`react-chores-fragment-${id}-index-${choreIndex}`}
                    >
                      {items?.map((item, itemIndex) => {
                        let choreIcon = <AutoAwesome color="action" />;
                        if (item.id && choreIcons[item.id]) {
                          choreIcon = choreIcons[item.id];
                        }
                        return (
                          <ListItemButton
                            key={`list-item-${id}-index${itemIndex}`}
                            divider
                            disableRipple
                            disableTouchRipple
                            disabled={!todayChore}
                          >
                            <ListItemAvatar>
                              <Avatar>{choreIcon}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Grid
                                  container
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  spacing={1}
                                >
                                  <Grid item>{item.name}</Grid>
                                  <Grid item>
                                    <Typography variant="caption">
                                      {username?.displayName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              }
                              secondary={item?.description}
                            />
                          </ListItemButton>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </List>
      </Paper>
    </React.Fragment>
  );
}

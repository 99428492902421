import {
  CssBaseline,
  Paper,
  List,
  ListSubheader,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Grid,
  Tooltip,
  IconButton,
  Typography,
  Button,
  Box,
  Fade,
} from "@mui/material";
import React, { useState } from "react";
import { SplitsType, SplitsUserType, ViewSplitType } from "../../types";
import CustomAvatar from "../../components/CustomAvatar";
import moment from "moment";
import { currencyFormatter } from "../../helpers";
import { useAppSelector } from "../../redux/store";
import { ceil, filter, sumBy } from "lodash";
import { CurrencyRupee } from "@mui/icons-material";
import SplitConfirmation from "./SplitConfirmation";
import useSplits from "../../hooks/useSplits";
import { doc } from "firebase/firestore";
import { firestore } from "../../services/firebase";

type SplitListPropsType = {
  items: SplitsType[];
  setViewSplit: (arg: ViewSplitType) => void;
};

export default function SplitList(props: SplitListPropsType) {
  const { pushNewSplit } = useSplits();
  const [currentSplit, setCurrentSplit] = useState(false);
  const { users } = useAppSelector((state) => state.common);
  const { authUser, expenses } = useAppSelector((state) => state.user);
  const expenseTotal = sumBy(expenses, "amount");
  const canSplit = expenseTotal >= 10000 || (expenses?.length || 0) >= 20;
  const splitUsers: SplitsUserType[] = users.map((user) => {
    const userExpenses = filter(expenses, (e) => e.user?.id === user.id);
    const userExpensesTotal = sumBy(userExpenses, "amount");
    return {
      id: user.id,
      amount: userExpensesTotal,
      userRef: doc(firestore, "users", user.id as string),
      user,
      paidByRefs: [],
      paidByUsers: [],
    };
  });

  const handleSplitApprove = () => {
    if (expenses?.length && canSplit) {
      pushNewSplit(expenses, splitUsers);
      setCurrentSplit(false);
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Paper square elevation={3} sx={{ height: "100%" }}>
        <List disablePadding>
          {expenseTotal > 0 && (
            <React.Fragment>
              <ListSubheader component={"div"}>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid item>
                    <Box display={"flex"} gap={1} alignItems={"center"}>
                      <Typography variant="subtitle2">
                        Current Expenses
                      </Typography>
                      <Typography variant="subtitle2" color={"limegreen"}>
                        {currencyFormatter.format(expenseTotal)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Fade in={canSplit}>
                    <Grid item>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => setCurrentSplit(true)}
                      >
                        Split
                      </Button>
                    </Grid>
                  </Fade>
                </Grid>
              </ListSubheader>
              {splitUsers.map((splitUser) => {
                const userExpensesSplit = ceil(
                  Number(splitUser.amount) / splitUsers.length
                );

                if (splitUser.amount === 0) {
                  return null;
                }

                return (
                  <ListItemButton
                    key={`NewSplit-${splitUser.id}`}
                    divider
                    disableRipple
                    disableTouchRipple
                  >
                    <ListItemAvatar>
                      <CustomAvatar name={splitUser?.user?.displayName} />
                    </ListItemAvatar>
                    <ListItemText
                      primaryTypographyProps={{ component: "div" }}
                      sx={{ justifyContent: "space-between" }}
                      secondaryTypographyProps={{ component: "div" }}
                      primary={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Typography
                            fontWeight={600}
                            variant="subtitle1"
                            color={"limegreen"}
                          >
                            {currencyFormatter.format(Number(splitUser.amount))}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            fontWeight={100}
                          >{`${currencyFormatter.format(userExpensesSplit)} × ${
                            splitUsers.length
                          }`}</Typography>
                        </Box>
                      }
                      secondary={splitUser?.user?.displayName}
                    />
                  </ListItemButton>
                );
              })}
            </React.Fragment>
          )}
          {props.items.map((split, splitIndex) => {
            const splitDate = moment(split.createdAt).format("llll");

            return (
              <React.Fragment key={`SplitList-item-${splitIndex}`}>
                <ListSubheader component={"div"}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Grid item>
                      <Typography variant="subtitle2">{splitDate}</Typography>
                    </Grid>
                    <Fade>
                      <Grid item>
                        <Button size="small" startIcon={<CurrencyRupee />}>
                          Expenses
                        </Button>
                      </Grid>
                    </Fade>
                  </Grid>
                </ListSubheader>
                {split.users?.map((splitUser, splitUserIndex) => {
                  const amountSplit = splitUser.amount
                    ? ceil(splitUser.amount / 3)
                    : 0;
                  return (
                    <ListItemButton
                      key={`SplitListSkeleton-ListItemButton-${splitUserIndex}`}
                      onClick={() =>
                        props.setViewSplit({ ...splitUser, split })
                      }
                      divider
                    >
                      <ListItemAvatar>
                        <CustomAvatar name={splitUser.user?.displayName} />
                      </ListItemAvatar>
                      <ListItemText
                        primaryTypographyProps={{ component: "div" }}
                        sx={{ justifyContent: "space-between" }}
                        secondaryTypographyProps={{ component: "div" }}
                        primary={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Typography
                              fontWeight={600}
                              variant="subtitle1"
                              color={"limegreen"}
                            >
                              {currencyFormatter.format(
                                Number(splitUser.amount)
                              )}
                            </Typography>
                            <Typography variant="subtitle2" fontWeight={100}>
                              {`${currencyFormatter.format(amountSplit)} × ${
                                users.length
                              }`}
                            </Typography>
                          </Box>
                        }
                        secondary={splitUser.user?.displayName}
                      />
                      {splitUser.user?.id === authUser?.uid && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                          }}
                        >
                          {splitUser.paidByUsers?.map(
                            (paidByUser, paidByUserIndex) => {
                              return (
                                <Tooltip
                                  key={`SplitListItem-${splitIndex}-pay-by-${paidByUserIndex}`}
                                  title={`Paid by ${paidByUser.displayName}`}
                                >
                                  <IconButton sx={{ p: 0.5 }}>
                                    <CustomAvatar
                                      name={paidByUser.displayName}
                                      sx={{
                                        height: 24,
                                        width: 24,
                                        fontSize: 12,
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              );
                            }
                          )}
                        </Box>
                      )}
                    </ListItemButton>
                  );
                })}
              </React.Fragment>
            );
          })}
        </List>
      </Paper>
      <SplitConfirmation
        open={currentSplit}
        handleClose={() => setCurrentSplit(false)}
        handleApprove={handleSplitApprove}
      />
    </React.Fragment>
  );
}

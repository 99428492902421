import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { Container, Fade, Paper } from "@mui/material";
import ChoreList from "./ChoreList";
import useDailyChores from "../../hooks/useDailyChores";
import ChoreListSkeleton from "./ChoreListSkeleton";

export default function Home() {
  const { initDailyChore, dailyChores } = useDailyChores();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container sx={{ p: 0 }} className="mobile-safe-area">
        <Paper square>
          <Fade in>
            <Typography variant="h5" fontWeight={500} sx={{ px: 2, py: 3 }}>
              Daily Chores
            </Typography>
          </Fade>
          {initDailyChore && <ChoreListSkeleton />}
          {!initDailyChore && (
            <ChoreList items={dailyChores ? dailyChores : []} />
          )}
        </Paper>
      </Container>
    </React.Fragment>
  );
}

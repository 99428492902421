import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Container, Grid, Typography, Zoom } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { UserStateExpenseType } from "../../types";
import moment from "moment";
import CustomAvatar from "../../components/CustomAvatar";
import { useAppSelector } from "../../redux/store";
import { DeleteOutline, Done } from "@mui/icons-material";
import { currencyFormatter } from "../../helpers";

type ViewExpensePropsType = {
  open: boolean;
  viewExpenseData: UserStateExpenseType;
  setOpen: (arg: boolean) => void;
  handleDeleteExpense?: (id: string) => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

export default function ViewExpense(props: ViewExpensePropsType) {
  const { authUser } = useAppSelector((state) => state.user);
  const { open, viewExpenseData, setOpen, handleDeleteExpense } = props;
  const { id, amount, details, user, date, createdAt } = viewExpenseData;
  const [isAuthUser, setIsAuthUser] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if (id && handleDeleteExpense) {
      handleDeleteExpense(id);
      setOpen(false);
    }
  };

  React.useEffect(() => {
    if (authUser?.uid === user?.id) {
      setIsAuthUser(true);
    } else {
      setIsAuthUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, authUser]);

  return (
    <Dialog
      open={open}
      maxWidth={"sm"}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth
    >
      <DialogTitle component={"div"}>
        <Grid
          container
          spacing={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item>
            <Typography variant={"h5"} fontWeight={600}>
              {user?.displayName}
            </Typography>
          </Grid>
          <Grid item>
            <CustomAvatar name={user?.displayName} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Typography color={"limegreen"} fontWeight={600}>
                    {currencyFormatter.format(Number(amount))}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight={500} sx={{ opacity: 0.5 }}>
                    Amount
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Typography>{moment(date).format("LL")}</Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight={500} sx={{ opacity: 0.5 }}>
                    Date
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs>
                  <Typography>{details}</Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight={500} sx={{ opacity: 0.5 }}>
                    Details
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {isAuthUser && (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <Typography>{moment(createdAt).format("llll")}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography fontWeight={500} sx={{ opacity: 0.5 }}>
                      Logged At
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Container sx={{ p: 2 }}>
          <Grid container spacing={3}>
            {isAuthUser && (
              <Grid item xs>
                <Button
                  size="large"
                  color="error"
                  variant="contained"
                  onClick={handleDelete}
                  startIcon={<DeleteOutline />}
                  fullWidth
                >
                  Delete
                </Button>
              </Grid>
            )}
            <Grid item xs>
              <Button
                size="large"
                color="success"
                variant="contained"
                onClick={handleClose}
                startIcon={<Done />}
                fullWidth
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        </Container>
      </DialogActions>
    </Dialog>
  );
}

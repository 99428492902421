import { useEffect, useState } from "react";
import { useAppSelector } from "../redux/store";
import { useDispatch } from "react-redux";
import { showToast } from "../redux/slices/commonSlice";
import { getDailyChores } from "../services/firebase/chore.service";
import useCommonData from "./useCommonData";
import { DocumentReference } from "firebase/firestore";
import { loadDailyChores } from "../redux/slices/userSlice";
import { UserStateDailyChoreType } from "../types";
import { find } from "lodash";

export default function useDailyChores() {
  const dispatch = useDispatch();
  const { fetchAllUsers, fetchAllChores } = useCommonData();
  const { initialize, authUser, dailyChores } = useAppSelector(
    (state) => state.user
  );
  const { commonInitialize, users, chores } = useAppSelector(
    (state) => state.common
  );

  const [initDailyChore, setInitDailyChore] = useState(true);

  const getUser = (path: string) => {
    return find(users, (u) => `users/${u.id}` === path);
  };

  const getChoreItem = (path: string) => {
    return find(chores, (c) => `chores/${c.id}` === path);
  };

  const prepareDailyChores = (data: UserStateDailyChoreType[]) => {
    const dailyChores = data.map((d) => {
      const chores = d.chores?.map((chore) => {
        const userRef = chore.username as DocumentReference;
        chore.username = getUser(userRef.path);
        chore.items = chore.items?.map((item) => {
          const itemRef = item as DocumentReference;
          const choreItem = getChoreItem(itemRef.path);
          return {
            id: choreItem?.id,
            name: choreItem?.name,
            description: choreItem?.description,
          };
        });
        return chore;
      });
      return {
        ...d,
        chores,
      };
    });
    dispatch(loadDailyChores(dailyChores));
  };

  const fetchDailyChores = () => {
    if (
      commonInitialize.includes("users") ||
      commonInitialize.includes("chores")
    ) {
      return;
    }
    // Get Daily Chores
    getDailyChores()
      .then((result) => {
        prepareDailyChores(result);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error({ errorCode, errorMessage });
        dispatch(showToast({ message: errorCode, severity: "error" }));
      });
  };

  useEffect(() => {
    if (!initialize.includes("dailyChores")) {
      setInitDailyChore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialize]);

  useEffect(() => {
    fetchAllUsers();
    fetchAllChores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    fetchDailyChores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, chores]);

  useEffect(() => {
    fetchDailyChores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { initDailyChore, dailyChores };
}

import { updateProfile, User } from "firebase/auth";
import { auth, firestore } from ".";
import { collection, doc, getDocs, limit, query, setDoc } from "firebase/firestore";
import { CommonStateUserType, UpdateAuthUserProfileArgType } from "../../types";
import { FirebaseError } from "firebase/app";

export const updateAuthProfile = async (args: UpdateAuthUserProfileArgType) => {
  const user = auth.currentUser as User;
  if (user) {
    try {
      await updateProfile(user, args)
      const userRef = doc(firestore, "users", user.uid)
      setDoc(userRef, { displayName: user.displayName }, { merge: true })
    } catch (error) {
      const firebaseError = error as FirebaseError
      const errorCode = firebaseError.code;
      const errorMessage = firebaseError.message;
      console.log({ errorCode, errorMessage });
    }
  }
}

export const getAllUsers = async () => {
  const choreRef = collection(firestore, "users")
  const q = query(choreRef, limit(10));
  const querySnapshot = await getDocs(q);
  const users: CommonStateUserType[] = []
  querySnapshot.forEach((doc) => {
    users.push({
      id: doc.id,
      ...doc.data()
    });
  });
  return users
}

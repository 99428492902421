import { useEffect, useState } from "react";
import { useAppSelector } from "../redux/store";
import { useDispatch } from "react-redux";
import {
  injectUserSplit,
  loadUserSplits,
  showToast,
} from "../redux/slices/commonSlice";
import useCommonData from "./useCommonData";
import { SplitsType, SplitsUserType, UserStateExpenseType } from "../types";
import { find } from "lodash";
import {
  generateSplitExpenses,
  getSplits,
  markPaidBy,
} from "../services/firebase/split.services";

export default function useSplits() {
  const dispatch = useDispatch();
  const [fetchingUserSplits, setFetchingUserSplits] = useState(false);
  const { fetchAllUsers } = useCommonData();
  const { authUser } = useAppSelector((state) => state.user);
  const { commonInitialize, users, userSplits } = useAppSelector(
    (state) => state.common
  );

  const [initSplits, setInitSplits] = useState(true);

  const getUser = (path: string) => {
    return find(users, (u) => `users/${u.id}` === path);
  };

  const prepareUserSplit = (splitUser: SplitsUserType): SplitsUserType => {
    const user = getUser(splitUser.userRef?.path || "");
    const paidByUsers = splitUser.paidByRefs?.map((paidByRef) => {
      const paidByUser = getUser(paidByRef.path || "");
      if (paidByUser) {
        return paidByUser;
      }
      return { id: paidByRef.path };
    });
    return {
      ...splitUser,
      paidByUsers,
      user,
    };
  };

  const prepareSplits = (data: SplitsType[], updatedSplit?: SplitsType) => {
    const splits = data.map((split) => {
      if (updatedSplit && split.id === updatedSplit.id) {
        return {
          ...updatedSplit,
          users: updatedSplit.users?.map((user) => prepareUserSplit(user)),
        };
      } else {
        return {
          ...split,
          users: split.users?.map((user) => prepareUserSplit(user)),
        };
      }
    });
    dispatch(loadUserSplits(splits));
  };

  const fetchSplits = () => {
    setFetchingUserSplits(true);
    if (commonInitialize.includes("users") || fetchingUserSplits) {
      return;
    }
    // Get Splits
    getSplits()
      .then((result) => {
        prepareSplits(result);
        setFetchingUserSplits(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error({ errorCode, errorMessage });
        dispatch(showToast({ message: errorCode, severity: "error" }));
        setFetchingUserSplits(false);
      });
  };

  const pushNewSplit = (
    expenses: UserStateExpenseType[],
    splitUsers: SplitsUserType[]
  ) => {
    const expenseIds = expenses.map((expense) => expense.id as string);
    generateSplitExpenses(expenseIds, splitUsers)
      .then((result: SplitsType) => {
        const split = {
          ...result,
          users: result.users?.map((user) => prepareUserSplit(user)),
        };
        dispatch(injectUserSplit(split));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error({ errorCode, errorMessage });
        dispatch(
          showToast({ message: errorCode || errorMessage, severity: "error" })
        );
      });
  };

  const markPaid = (split: SplitsType, userId: string, paidById: string) => {
    markPaidBy(split, userId, paidById)
      .then((result) => {
        prepareSplits(userSplits, result);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error({ errorCode, errorMessage });
        dispatch(
          showToast({ message: errorCode || errorMessage, severity: "error" })
        );
      });
  };

  useEffect(() => {
    if (!commonInitialize.includes("userSplits")) {
      setInitSplits(false);
    }
  }, [commonInitialize]);

  useEffect(() => {
    fetchSplits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    fetchAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  useEffect(() => {
    fetchSplits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    authUser,
    initSplits,
    userSplits,
    pushNewSplit,
    markPaid,
  };
}

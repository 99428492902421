import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Container, Grid, Typography, Zoom } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { UserStateChoreUsernameType, ViewSplitType } from "../../types";
import CustomAvatar from "../../components/CustomAvatar";
import { useAppSelector } from "../../redux/store";
import { Done, DoneAll } from "@mui/icons-material";
import { ceil, filter, find } from "lodash";
import { currencyFormatter } from "../../helpers";
import MarkPaidConfirmation from "./MarkPaidConfirmation";
import useSplits from "../../hooks/useSplits";

type ViewExpensePropsType = {
  open: boolean;
  viewSplit: ViewSplitType;
  closeViewSplit: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

export default function ViewSplit(props: ViewExpensePropsType) {
  const { markPaid } = useSplits();
  const { users } = useAppSelector((state) => state.common);
  const { authUser } = useAppSelector((state) => state.user);
  const { open, viewSplit, closeViewSplit } = props;
  const [markPaidUser, setMarkPaidUser] =
    React.useState<UserStateChoreUsernameType>({});
  const { user, amount, paidByUsers, split } = viewSplit;

  const amountSplit = ceil(amount ? amount / 3 : 0);

  const toBePaid = filter(users, (uItem) => uItem.id !== user?.id);

  const handleMarkPaid = () => {
    if (split && authUser?.uid && markPaidUser.id) {
      markPaid(split, authUser?.uid, markPaidUser.id);
      setMarkPaidUser({});
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        maxWidth={"sm"}
        onClose={closeViewSplit}
        TransitionComponent={Transition}
        fullWidth
      >
        <DialogTitle component={"div"}>
          <Grid
            container
            spacing={1}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item xs={10}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant={"h5"} fontWeight={600}>
                    {user?.displayName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems={"center"}>
                    <Grid item>
                      <Typography variant="h6" color={"limegreen"}>
                        {currencyFormatter.format(Number(amount))}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">
                        {`${currencyFormatter.format(amountSplit)} × ${
                          users.length
                        }`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <CustomAvatar name={user?.displayName} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            <Grid container spacing={2}>
              {toBePaid.map((toBePaidUserItem, toBePaidUserItemIndex) => {
                return (
                  <Grid
                    key={`ViewSplit-User-Pay-${toBePaidUserItemIndex}`}
                    item
                    xs={12}
                  >
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Grid item>
                        <Typography>{toBePaidUserItem.displayName}</Typography>
                      </Grid>
                      <Grid item>
                        {user?.id === authUser?.uid && (
                          <React.Fragment>
                            {find(paidByUsers, { id: toBePaidUserItem.id }) ? (
                              <Button
                                size="small"
                                color="success"
                                variant="outlined"
                                startIcon={<DoneAll />}
                                disabled
                              >
                                {currencyFormatter.format(amountSplit)}
                              </Button>
                            ) : (
                              <Button
                                size="small"
                                color="success"
                                startIcon={<Done />}
                                variant={"contained"}
                                onClick={() =>
                                  setMarkPaidUser(toBePaidUserItem)
                                }
                              >
                                {currencyFormatter.format(amountSplit)}
                              </Button>
                            )}
                          </React.Fragment>
                        )}
                        {user?.id !== authUser?.uid && (
                          <Button
                            size="small"
                            color="success"
                            variant="outlined"
                            disabled
                          >
                            {currencyFormatter.format(amountSplit)}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Container sx={{ p: 2 }}>
            <Button
              size="large"
              color="success"
              variant="contained"
              onClick={closeViewSplit}
              fullWidth
            >
              Ok
            </Button>
          </Container>
        </DialogActions>
      </Dialog>
      <MarkPaidConfirmation
        open={!!markPaidUser?.id}
        user={markPaidUser}
        handleClose={() => setMarkPaidUser({})}
        handleApprove={handleMarkPaid}
      />
    </React.Fragment>
  );
}

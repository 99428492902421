import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface NoDataPlaceholderProps {
  message?: string;
}

export default function NoDataPlaceholder({
  message = "No data available",
}: NoDataPlaceholderProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "30vh",
      }}
    >
      <Typography variant="h6" component="div" align="center">
        {message}
      </Typography>
    </Box>
  );
}

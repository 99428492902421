import { collection, query, limit, getDocs, addDoc, getDoc, doc, deleteDoc, where } from "firebase/firestore";
import { firestore } from ".";
import { AddExpensePayloadType, UserStateExpenseType } from "../../types";

export const getExpenses = async () => {
  const expenseRef = collection(firestore, "expenses")
  const q = query(
    expenseRef,
    where("status", "==", "logged"),
    limit(100)
  );
  const querySnapshot = await getDocs(q);
  const expenses: UserStateExpenseType[] = []
  querySnapshot.forEach((doc) => {
    expenses.push({
      ...doc.data(),
      id: doc.id,
    });
  });

  return expenses;
}

export const addExpense = async (expense: AddExpensePayloadType) => {
  const expenseRef = collection(firestore, "expenses")
  const userRef = doc(firestore, "users", expense.userId)
  const { amount, date, details } = expense
  const newExpenseRef = await addDoc(expenseRef, {
    userRef,
    amount,
    date,
    details,
    status: "logged",
    createdAt: new Date().toISOString()
  })
  console.log(newExpenseRef);
  const result = await getDoc(newExpenseRef)
  const newExpense = {
    ...result.data(),
    id: result.id
  }
  return newExpense;
}

export const deleteExpense = async (id: string) => {
  const expenseDocRef = doc(firestore, "expenses", id)
  await deleteDoc(expenseDocRef)
}

import React from "react";
import {
  Container,
  CssBaseline,
  Fab,
  Fade,
  Grid,
  Paper,
  SxProps,
  Theme,
  Typography,
  Zoom,
} from "@mui/material";
import useExpenses from "../../hooks/useExpenses";
import ExpenseList from "./ExpenseList";
import AddIcon from "@mui/icons-material/Add";
import AddExpense from "./AddExpense";
import ExpenseListSkeleton from "./ExpenseListSkeleton";
import { currencyFormatter } from "../../helpers";

const fabStyle: SxProps<Theme> = {
  position: "fixed",
  bottom: 118,
  right: 26,
  boxShadow: "0 0 10px 10px #AAAAAA30",
};

export default function Expenses() {
  const {
    initExpenses,
    authUser,
    expenses,
    expenseTotal,
    pushNewExpense,
    dropExpense,
  } = useExpenses();
  const [addExpenseOpen, setAddExpenseOpen] = React.useState(false);

  const handleAddExpense = (amount: number, date: string, details: string) => {
    if (authUser?.uid) {
      const payload = {
        userId: authUser.uid,
        amount,
        date,
        details,
      };
      pushNewExpense(payload);
    }
  };

  const handleDeleteExpense = (id: string) => {
    if (id) {
      dropExpense(id);
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container sx={{ p: 0 }} className="mobile-safe-area">
        <Paper square>
          <Fade in>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
              px={2}
              py={3}
              spacing={1}
            >
              <Grid item>
                <Typography variant="h5" fontWeight={500}>
                  Expenses
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  color={"limegreen"}
                  variant="h6"
                  fontWeight={700}
                  textTransform={"uppercase"}
                >
                  {currencyFormatter.format(expenseTotal)}
                </Typography>
              </Grid>
            </Grid>
          </Fade>
          {initExpenses && <ExpenseListSkeleton />}
          {!initExpenses && (
            <ExpenseList
              items={expenses?.length ? expenses : []}
              handleDeleteExpense={handleDeleteExpense}
            />
          )}
        </Paper>
        {!initExpenses && (
          <Zoom in>
            <Fab
              size="large"
              sx={fabStyle}
              color="success"
              aria-label="add"
              onClick={() => setAddExpenseOpen(true)}
            >
              <AddIcon />
            </Fab>
          </Zoom>
        )}
        <AddExpense
          open={addExpenseOpen}
          setOpen={setAddExpenseOpen}
          handleAddExpense={handleAddExpense}
        />
      </Container>
    </React.Fragment>
  );
}
